import styled from "styled-components";

export const KioskToggleDiv = styled.div`
                              position: relative;
padding: 10px;
width: 200px; /* Specific width for the kiosk switch */
height: 75px;
border-radius: 50px;
box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, .5), 0px 1px 1px 0 rgba(255, 255, 255, 1);
background: rgb(189, 191, 193);
background: linear-gradient(to bottom, rgba(189, 191, 193, 1) 0%, rgba(249, 251, 254, 1) 100%);
`;

export const KioskToggleInput = styled.input.attrs({ type: 'checkbox' })`
    position: absolute;
    opacity: 0;
    top: -3px;
    left: 17px;
    width: 180px; /* Adjust width to match the kiosk-specific toggle */
    height: 55px;
    z-index: 5;

    ~ div {
        width: 180px; /* Specific width for the kiosk toggle */
        height: 55px;
        border-radius: 50px;
        border: 1px solid #979797;
        box-shadow: inset 5px 0 0 0 rgba(0, 0, 0, .1), inset -5px 0 0 0 rgba(0, 0, 0, .1), inset -6px 0 0 0 rgba(255, 255, 255, .5), inset -4px 6px 11px -5px rgba(0, 0, 0, .2), inset 1px 1px 0 0 rgba(255, 255, 255, 1), -6px 4px 11px -7px rgba(0, 0, 0, .5);
        background: rgb(253, 252, 251);
        background: linear-gradient(to right, rgba(253, 252, 251, 1) 37%, rgba(229, 229, 229, 1) 61%);

        :before {
            content: attr(data-unchecked);
            display: block;
            position: absolute;
            top: 27px;
            left: 20px; /* Specific positioning for kiosk labels */
            font-size: 1.2em;
        }

        :after {
            content: attr(data-checked);
            color: #119400;
            text-shadow: 0 1px 0 #fff, 0px 0 7px #007a08;
            display: block;
            position: absolute;
            top: 27px;
            left: 115px; /* Specific positioning for kiosk labels */
            font-size: 1.2em;
        }
    }

    &:checked {
        ~ div {
            box-shadow: inset -5px 0 0 0 rgba(0, 0, 0, .1), inset 5px 0 0 0 rgba(0, 0, 0, .1), inset 6px 0 0 0 rgba(255, 255, 255, .5), inset -4px 6px 11px -5px rgba(0, 0, 0, .2), inset 1px 1px 0 0 rgba(255, 255, 255, 1), -6px 4px 11px -7px rgba(0, 0, 0, .5);
            background: rgb(229, 229, 229);
            background: linear-gradient(to right, rgba(229, 229, 229, 1) 39%, rgba(253, 252, 251, 1) 63%);

            :before {
                color: #119400;
                text-shadow: 0 1px 0 #fff, 0px 0 7px #007a08;
            }

            :after {
                color: #000;
                text-shadow: none;
            }
        }
    }
`;