import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from "react-router-dom";

const SplashScreen = ({ closeLanding, className }) => {
    const iframeRef = useRef(null);
    const location = useLocation();
    const [splashType, setSplashType] = useState("iframe"); // Default to iframe

    useEffect(() => {
        const handleStorageChange = () => {
            try {
                const posData = JSON.parse(localStorage.getItem("posData") || "{}");
                setSplashType(posData.splashType || "iframe");
            } catch (error) {
                console.error("Error parsing posData:", error);
            }
        };

        // Read splashType on mount
        handleStorageChange();

        // Listen for storage changes (for real-time updates)
        window.addEventListener("storage", handleStorageChange);
        return () => window.removeEventListener("storage", handleStorageChange);
    }, []);

    // Clear countdown if the path is root
    useEffect(() => {
        if (location.pathname === '/' && localStorage.getItem('timeoutID')) {
            clearTimeout(Number(localStorage.getItem('timeoutID')));
            localStorage.removeItem('timeoutID');
        }
    }, [location.pathname]);

    const handleOverlayClick = () => {
        closeLanding();
    };

    return (
        <div id="iframeContainer"
             style={{
                 position: 'absolute', width: '100vw', height: '100vh', top: 0, zIndex: 9999,
                 display: className === 'hide' ? 'none' : ''
             }}>
            {splashType === 'video' ? (
                // Load video from public/
                <video
                    controls={false}
                    autoPlay={true}
                    muted={true}
                    style={{
                        width: '100vw',
                        height: '100vh',
                        objectFit: 'cover',
                    }}
                >
                    <source src="/videos/splash.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            ) : (
                // Load the existing iframe
                <iframe
                    id="iframe_sodaclick_design"
                    ref={iframeRef}
                    src="https://html5.sodaclick.com/playlist/TGVrYUNjVHpldjJFaG93R2ZHV2sxM1J1MzV0UEVnWWgvU0d2N0c3MWh5VT0=/playlist?id=1&playlist=true&pwa=true"
                    style={{
                        width: '100vw',
                        height: '100%',
                        border: 'none',
                        zIndex: 1000,
                        top: 0,
                        margin: 0,
                        padding: 0,
                        left: 0,
                    }}
                ></iframe>
            )}

            {/* Overlay to close the splash screen */}
            <div onClick={handleOverlayClick}
                 style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', cursor: 'pointer' }}
            ></div>
        </div>
    );
};

export default SplashScreen;