// test
import {Col, Row} from "antd";

import {GlobalStatesContext} from "context/GlobalStatesContext";
import useDetailsData from "hooks/useDetailsData";
import {useCallback, useContext, useEffect, useState} from "react";
import {BiInfoCircle} from "react-icons/bi";
import {GrFormClose} from "react-icons/gr";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useNavigate, useSearchParams,} from "react-router-dom";
import {extractItems, getChildsArray, isCategoryIncludesIn, renderMessage,} from "utils/helper";
import {CATAGORIES} from "../../../constants/CATAGORIES";
import {addToCart, removeCartItem, setOpenCart, updateCartItem, updateItemPrice,} from "../../../Redux/cartSlice";
import {
    selectEditedProductIndex,
    selectGetSelectedProduct,
    setEditedProductFromOrderList,
    setSelectedProduct,
    updateSelectedProduct,
} from "../../../Redux/productSlice";
import {is, not} from "../../modals/helper";
import QuantityController from "./components/QuantityController";
import {isCoffeeSelected, isMealDeal} from "./helper";
import {CloseIcon, InfoMessage, OrderContainer, SelectedItems, SelectImg, WarningMessage,} from "./index.style";

const SelectOrder = ({
                         item,
                         childItemsList,
                         visible,
                         setVisible,
                         editingMeal,
                         index,
                         handleInfo,
                         parantProductId
                     }) => {
    console.log(item, " childItemsList...");
    const haveModifires = is(item?.modifiers);
    const {haveDetails} = useDetailsData();

    const {globalState, setGlobalState} = useContext(GlobalStatesContext);
    const [searchParams] = useSearchParams();
    const dealItemCost = searchParams.get("dealItemCost");
    const parentSize = searchParams.get("parentSize");
    const isDealItem = searchParams.get("isDealItem");

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [itemsList, setItemsList] = useState({});
    const [childItems, setChildItems] = useState([]);
    // const [childItemsList, setChildItemsList] = useState({});
    const [reverseItemsList, setReverseItemsList] = useState([]);

    /* Sate to hold if in Breakfast category there is coffe selected or not for MealDeals.
    * https://sodaclick-status.atlassian.net/issues/?jql=project+%3D+%22OKB%22+ORDER+BY+created+DESC&atlOrigin=eyJpIjoiZTRkMjY2NDlhZWJjNDA2OTk4YzFjMDU3NmNmN2Q4NGYiLCJwIjoiaiJ9
    */
    const [coffeeSelected, setCoffeeSelected] = useState(null);


    console.log(childItemsList, "childItemsList");
    useEffect(() => {
        if (Object.keys(itemsList || {})?.length) {
            let keysArray = Object.keys(itemsList);
            setReverseItemsList(keysArray);
        }
    }, [itemsList]);

    useEffect(() => {
        setItemsList(extractItems(item));
    }, [item?.items]);

    useEffect(() => {
        const childs = getChildsArray(item?.childs);
        const list = childs?.map((childItem) => {
            return {
                name: childItem.displayName,
                items: extractItems(childItem),
            };
        });
        setChildItems(list);
    }, [item, childItemsList]);

    /** Save childs extras sepratly */
    /** Structure will be like this:
     *
     * Hot Coffee:
     *  Items...
     *
     */

    const increaseProductQty = useCallback(() => {
        if (index || index === 0) {
            dispatch(
                updateItemPrice({...item, quantity: item?.quantity + 1, index})
            );
        } else {
            debugger
            dispatch(
                updateSelectedProduct({...item, quantity: item?.quantity + 1})
            );
        }
    }, [item, index]);

    const handleQuantityChange = useCallback((newQuantity) => {
        if (newQuantity <= 0) {
            if (typeof index !== "undefined" && index !== null) {
                dispatch(removeCartItem({index}));
            } else {
                dispatch(updateSelectedProduct({...item, quantity: 0}));
            }
        } else {
            if (typeof index !== "undefined" && index !== null) {
                dispatch(updateItemPrice({index, quantity: newQuantity}));
            } else {
                dispatch(updateSelectedProduct({...item, quantity: newQuantity}));
            }
        }
    }, [dispatch, index, item]);

    const decreaseProductQty = useCallback(() => {
        if (item?.quantity > 1) {
            if (index || index === 0) {
                dispatch(
                    updateItemPrice({...item, quantity: item?.quantity - 1, index})
                );
            } else {
                dispatch(
                    updateSelectedProduct({...item, quantity: item?.quantity - 1})
                );
            }
        } else if ((item.quantity === 1 && index) || index === 0) {
            dispatch(removeCartItem({index, id: item?._id}));
        }
    }, [item]);

    const deleteSelectOrder = () => {
        if ((item.quantity > 1 && index) || index === 0) {
            dispatch(removeCartItem({index, id: item?._id}));
        }
    };
    const location = useLocation("");


    const handleClick = () => {
        // Constant array for deals items to make sure Coffee selection compulsory.
        const breakfastDealsCodes = ['MP-883852', 'MP-883849', 'MP-8838100', 'MP-8838103'];

        const isDealItemForBreakfast = item => (
            item?.category === 'Breakfast' && isMealDeal(item.productCode, breakfastDealsCodes)
        );

        const itemDealDetails = {
            dealsList: breakfastDealsCodes,
            selectedItems: item
        };

        if (isDealItemForBreakfast(item) && isCoffeeSelected(itemDealDetails)) {
            setCoffeeSelected(true);
        } else if (isDealItemForBreakfast(item) && !isCoffeeSelected((itemDealDetails))) {
            setCoffeeSelected(false);
            return;
        }

        if (index || index === 0) {
            dispatch(
                setSelectedProduct({
                    ...item,
                    sizeSelected: true,
                    editing: true,
                    // originalPriceOfItem: globalState?.customOrder?.originalPriceOfItem,
                })
            );


            const productId = globalState?.parentProductId;

            setGlobalState((prev) => {
                if (!prev[productId]) {
                    return prev;  // return the existing state if productId does not exist
                } else {
                    const {customOrder, ...remainingPropertiesForProduct} = prev[productId];
                    return {
                        ...prev,
                        [productId]: {...remainingPropertiesForProduct}
                    };
                }
            });

            dispatch(setEditedProductFromOrderList({index}));
            navigate(`/customize_order/${item?.category}/${item?.productCode}`);
        } else {
            const productId = globalState?.parentProductId;

            dispatch(setOpenCart(true));
            dispatch(
                addToCart({
                    ...item,
                    modifiers:
                        globalState?.[productId]?.customOrder?.currentSelectedModifires ||
                        item?.modifiers,
                    total:
                        item?.total || Number(item?.cost || item?.price) * item?.quantity,
                    childs: {...globalState?.[productId]?.customOrder?.childs},
                })
            );


            setGlobalState((prev) => {
                if (!prev[productId]) {
                    return prev;  // return the existing state if productId does not exist
                } else {
                    const {customOrder, ...remainingPropertiesForProduct} = prev[productId];
                    return {
                        ...prev,
                        [productId]: {...remainingPropertiesForProduct}
                    };
                }
            });
            debugger
            navigate("/");
        }
    };

    const handleUpdate = () => {
        dispatch(setSelectedProduct({...item, editing: false}));
        dispatch(updateCartItem({index: editedProductIndex, item: {...item}}));

        navigate("/your_order");
    };

    const editedProductIndex = useSelector(selectEditedProductIndex);
    const selectedProduct = useSelector(selectGetSelectedProduct);

    console.log(selectedProduct, "Selected product");
    const handleAddToMeal = (e) => {
        e.preventDefault();

        const productId = globalState?.parentProductId;

        setGlobalState((prev) => {
            return {
                ...prev,
                [productId]: {
                    ...prev[productId],
                    customOrder: {
                        ...prev[productId]?.customOrder,
                        childItemPrice: item?.cost,
                        updatedItem: {
                            displayItemName: item?.displayName,
                            itemCost: item?.price || item?.cost,
                            cost: item?.cost || item?.price,
                            size: item?.size,
                        },
                        actualCostOfMeal: Number(
                            (prev[productId]?.customOrder?.actualCost + item?.cost) - (prev[productId]?.customOrder?.previousChildItemsCost || 0)
                        ) || 0,
                        currentSelectedExtras: {
                            ...prev[productId]?.customOrder?.currentSelectedExtras,
                        },
                        currentSelectedModifires: [
                            {
                                ...prev[productId]?.customOrder?.currentSelectedModifires?.[0],
                            },
                        ],
                        parentSelectedSize: prev?.[productId]?.customOrder?.selectedSize,
                        childs: {
                            [item?.productCode]: {
                                ...item,
                            },
                        },
                    },
                },
            };
        });


        navigate(-1);
    };

    console.log(itemsList, " itemsList");
    const isThisChildItem = (name) => {
        return childItems?.find((item) => item?.name === name);
    };

    const renderButtonText = useCallback(() => {

        if (globalState?.[globalState?.parentProductId]?.customOrder?.item?.productCode === item?.productCode) {
            return (
                <span
                    className={not(haveModifires) ? "disabled" : ""}
                    onClick={handleAddToMeal}
                >
          Add to Meal
        </span>
            );
        } else if (index || index === 0) {
            return (
                (haveModifires && (
                    <span
                        className={not(haveModifires) ? "disabled" : ""}
                        onClick={(haveModifires && handleClick) || null}
                    >
            Edit
          </span>
                )) ||
                ""
            );
        } else if (
            editingMeal ||
            item?.editing ||
            selectedProduct?.editing ||
            (selectedProduct?.editing && selectedProduct?.isMeal)
        ) {
            // console.log(selectedProduct);
            return <span onClick={handleUpdate}>Update</span>;
        } else {
            return <span onClick={handleClick}>Add To Cart</span>;
        }
    }, [selectedProduct, item, index, haveModifires, globalState]);

    const renderSize = (item) => {
        if (item?.product?.size && item?.product?.size !== "NA") {
            return item.product.size;
        } else if (item?.size !== "NA") {
            return item.size;
        }
        return "";
    };

    // console.log(MESSAGES, " MESSAGE..");

    return (
        <OrderContainer open={visible} onCancel={setVisible}>
            {location.pathname == "/your_order" ? (
                <CloseIcon>
                    <GrFormClose onClick={() => deleteSelectOrder()}/>
                </CloseIcon>
            ) : (
                ""
            )}
            <Row gutter={5}>
                <Col span={5}>
                    <SelectImg>
                        {/* <img src={item?.product?.image_url} alt="" /> */}
                        <img
                            draggable={false}
                            src={item?.product?.imageSrc || item?.imageSrc}
                            alt=""
                            loading="lazy"
                        />
                    </SelectImg>
                </Col>

                <Col span={13}>
                    <div>
                        <div style={{position: "relative"}}>
                            <h2 style={{display: "inline-block", paddingRight: "1.5em"}}>
                                <div style={{display: "flex"}}>
                                    {item?.product?.itemName || item?.itemName}
                                    {/* If there is size then add (Brackets) */}
                                    <p style={{marginLeft: 10}}>{renderSize(item) && `(${renderSize(item)})` || ""}</p>
                                </div>

                                {/* {console.log(item, "item")} */}
                                {location.pathname == "/your_order"
                                    ? ""
                                    : (haveDetails(item?.pluCode) && (
                                        <Link to={`/product_details/${item?.pluCode}`}>
                                            <BiInfoCircle onClick={() => handleInfo(item)}/>
                                        </Link>
                                    )) ||
                                    ""}
                            </h2>
                        </div>

                        {reverseItemsList?.map((elm) => {
                            const {displayItemName, itemCost, size} =
                            itemsList?.[elm] || {};
                            const {items} = isThisChildItem(displayItemName) || {};
                            return (
                                <>
                                    <SelectedItems>
                                        <div>
                                            <span>{displayItemName}</span>{" "}
                                            <span>{(size && item.size) ? `(${item?.size})` : "" || ""}</span>
                                        </div>
                                        <p>${itemCost}</p>
                                    </SelectedItems>
                                    <div style={{marginLeft: 10}}>
                                        {Object.keys(items || {})?.map((item, itemIndex) => {
                                            const {displayItemName, itemCost, size} =
                                            items?.[item] || {};
                                            return (
                                                <SelectedItems key={itemIndex}>
                                                    <div>
                                                        <span>{displayItemName}</span>{" "}
                                                    </div>
                                                    <span style={{marginLeft: 30}}>{size || ""}</span>
                                                    <p>${itemCost}</p>
                                                </SelectedItems>
                                            );
                                        })}
                                    </div>
                                </>
                            );
                        })}
                        {isCategoryIncludesIn(item?.category, [
                            CATAGORIES.COLD_DRINKS,
                            CATAGORIES.GRAB_AND_GO,
                            CATAGORIES.SNACKS,
                            CATAGORIES.LUNCH,
                        ]) && (
                            <div>
                                <InfoMessage>{renderMessage('info', item?.category)}</InfoMessage>
                            </div>
                        )}
                        {
                            coffeeSelected == false && (
                                <div>
                                    <WarningMessage>{renderMessage('warning', 'coffeeNotSelected')}</WarningMessage>
                                </div>
                            )
                        }
                        {console.log(childItems, " childItems")}
                    </div>
                </Col>

                <Col span={6}>
                    {not(item?.product?.isFree) && (
                        <>
                            <h2 className="totalCost" style={{textAlign: "right"}}>
                                $
                                {item?.total?.toFixed(2) ||
                                    ((item?.price || item?.cost) * item?.quantity).toFixed(2) ||
                                    item?.cost}
                            </h2>

                            <QuantityController
                                item={item}
                                onIncrease={() => handleQuantityChange(item.quantity + 1)}
                                onDecrease={() => handleQuantityChange(item.quantity - 1)}
                                renderButtonText={renderButtonText}
                                dealItemCost={null}
                            />
                        </>
                    )}
                </Col>

            </Row>
        </OrderContainer>
    );
};

export default SelectOrder;
