import React, { useEffect, useState } from "react";
import { InputContainer } from "../../../posData/index.style";
import { KioskToggleDiv, KioskToggleInput } from "./SplashToggle.styling";

const SplashTypeSwitcher = ({ onTypeChange }) => {
    const [isVideo, setIsVideo] = useState(false);

    useEffect(() => {
        // Retrieve the splash type from localStorage (inside posData)
        const posData = JSON.parse(localStorage.getItem("posData") || "{}");
        setIsVideo(posData.splashType === "video");
    }, []);

    useEffect(() => {
        // Save & notify type change whenever switch state changes
        const posData = JSON.parse(localStorage.getItem("posData") || "{}");
        const updatedPosData = {
            ...posData,
            splashType: isVideo ? "video" : "iframe",
        };
        localStorage.setItem("posData", JSON.stringify(updatedPosData));

        if (onTypeChange) {
            onTypeChange(isVideo ? "video" : "iframe");
        }
    }, [isVideo, onTypeChange]);

    const handleToggle = () => {
        setIsVideo((prev) => !prev);
    };

    return (
        <InputContainer style={{ textAlign: "center" }}>
            <p>Splash Type</p>
            <KioskToggleDiv onClick={handleToggle}>
                <KioskToggleInput checked={!isVideo} readOnly />
                <div data-unchecked="Iframe" data-checked="Video" />
            </KioskToggleDiv>
        </InputContainer>
    );
};

export default SplashTypeSwitcher;