// KioskTypeSwitcher.js
import React, { useEffect, useState } from "react";
import { InputContainer, ToggleDiv, ToggleInput } from "../../posData/index.style";
import { KioskToggleInput, KioskToggleDiv } from "./KisokToggle.styling";

const KioskTypeSwitcher = ({ onTypeChange }) => {
    const [isOutsideKiosk, setIsOutsideKiosk] = useState(false);

    useEffect(() => {
        // Retrieve the kiosk type from localStorage on mount
        const posData = JSON.parse(localStorage.getItem("posData") || "{}");
        setIsOutsideKiosk(posData.kioskType === "Outside Kiosk");
    }, []);

    useEffect(() => {
        // Save and notify type change whenever switch state changes
        const posData = JSON.parse(localStorage.getItem("posData") || "{}");
        const updatedPosData = {
            ...posData,
            kioskType: isOutsideKiosk ? "Outside Kiosk" : "Inside Kiosk",
        };
        localStorage.setItem("posData", JSON.stringify(updatedPosData));

        if (onTypeChange) {
            onTypeChange(isOutsideKiosk ? "Outside Kiosk" : "Inside Kiosk");
        }
    }, [isOutsideKiosk, onTypeChange]);

    const handleToggle = () => {
        setIsOutsideKiosk((prev) => !prev);
    };

    return (
        <InputContainer style={{ textAlign: "center" }}>
            <p>Kiosk Type</p>
            <KioskToggleDiv onClick={handleToggle}>
                <KioskToggleInput checked={!isOutsideKiosk} readOnly />
                <div data-unchecked="Inside" data-checked="Outside" />
            </KioskToggleDiv>
        </InputContainer>
    );
};

export default KioskTypeSwitcher;