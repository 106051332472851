import {Form} from "antd";

import KioskBoard from "kioskboard";
import React, {useEffect, useRef, useState} from "react";
import MyDateRangePicker from "../components/MyDateRangePicker";
import KioskTypeSwitcher from "../components/settings/KioskTypeSwitcher";
import SplashTypeSwitcher from "../components/settings/splash-type-switcher/SplashTypeSwitcher";
import {
    BtnContainer,
    Container,
    InputContainer,
    Location,
    SubmitBtn,
    Title,
    ToggleDiv,
    ToggleInput,
    Wrapper,
} from "../posData/index.style";

const PosData = () => {
    const storeLocationRef = useRef(null);
    const storeCodeRef = useRef(null);
    const surchargeRef = useRef(null);
    const addressRef = useRef(null);
    const phoneNumberRef = useRef(null);
    const [posLocations, setPosLocations] = useState({});
    const [scanner, setScanner] = useState(true);

    const [form] = Form.useForm();
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        const values = localStorage.getItem("posData");
        const parsedValues = (values && JSON.parse(values)) || null;
        if (parsedValues) {
            const {storeLocation, storeCode, username, password, pairCode, ...rest} =
                parsedValues;
            setPosLocations(rest);
            setInitialValues(parsedValues);
            form.setFieldsValue({
                ...parsedValues,
            });
        }
    }, []);

    const onFinish = async (values) => {
        // First, get the existing data from localStorage
        const existingPosData = JSON.parse(localStorage.getItem("posData") || "{}");

        // Next, merge the existing data with the new data
        const updatedPosData = {
            ...existingPosData, // this will spread all existing key-value pairs
            ...values, // this will overwrite previous values if they have the same keys, and add new ones
        };

        // Finally, save the updated data back to localStorage
        localStorage.setItem("posData", JSON.stringify(updatedPosData));
    };
    const onFinishFailed = (values) => {
    };

    const handleChange = (e) => {
        const {
            target: {name, value},
        } = e;
        form.setFieldsValue({[name]: value});
    };


    const setupKioskBoard = (ref) => {
        if (ref.current) {
            KioskBoard.run(ref.current, {
                language: "en",
                theme: "dark",
                allowRealKeyboard: true,
                allowMobileKeyboard: true,
                keysEnterCanClose: true,
                keysArrayOfObjects: [
                    {
                        0: "1",
                        1: "2",
                        2: "3",
                        3: "4",
                        4: "5",
                        5: "6",
                        6: "7",
                        7: "8",
                        8: "9",
                        9: "0",
                    },
                    {
                        0: "Q",
                        1: "W",
                        2: "E",
                        3: "R",
                        4: "T",
                        5: "Y",
                        6: "U",
                        7: "I",
                        8: "O",
                        9: "P",
                    },
                    {
                        0: "A",
                        1: "S",
                        2: "D",
                        3: "F",
                        4: "G",
                        5: "H",
                        6: "J",
                        7: "K",
                        8: "L",
                    },
                    {
                        0: "Z",
                        1: "X",
                        2: "C",
                        3: "V",
                        4: "B",
                        5: "N",
                        6: "M",
                    },
                ],
            });
        }
    };
    // lofff
    useEffect(() => {
        setupKioskBoard(storeLocationRef);
        setupKioskBoard(storeCodeRef);
        setupKioskBoard(surchargeRef);
        setupKioskBoard(addressRef);
        setupKioskBoard(phoneNumberRef);
    }, [
        surchargeRef,
        storeLocationRef,
        storeCodeRef,
        addressRef,
        phoneNumberRef,
    ]);

    const addEventListener = (ref, event, handler) => {
        if (ref.current) {
            ref.current.addEventListener(event, handler);
        }

        // Clean up the event listener when the component unmounts
        return () => {
            if (ref.current) {
                ref.current.removeEventListener(event, handler);
            }
        };
    };

    useEffect(() => {
        // Add event listeners for other refs
        const cleanupStoreLocationListener = addEventListener(
            storeLocationRef,
            "change",
            handleChange
        );

        const cleanupStoreCodeListener = addEventListener(
            storeCodeRef,
            "change",
            handleChange
        );

        const cleanupSurchargeListener = addEventListener(
            surchargeRef,
            "change",
            handleChange
        );

        const cleanupStoreAddressListener = addEventListener(
            storeLocationRef,
            "change",
            handleChange
        );

        const cleanupStorePhoneNumberListener = addEventListener(
            storeLocationRef,
            "change",
            handleChange
        );

        console.log(phoneNumberRef, ' phone number ref', phoneNumberRef.current);

        return () => {
            cleanupStoreLocationListener();
            cleanupStoreAddressListener();
            cleanupStoreCodeListener();
            cleanupStorePhoneNumberListener();
            cleanupSurchargeListener();
        };
    }, [storeLocationRef, storeCodeRef, addressRef, phoneNumberRef, surchargeRef, handleChange]);

    // Load the state of the scanner availibility
    useEffect(() => {
        setScanner(() => {
            const value = localStorage.getItem('scanner');
            if (value !== undefined) {

                return value === "true";
            }
            return true;
        });
    }, []);

    const handleSwitchScanner = ({target: {checked}}) => {
        setScanner(checked)
        localStorage.setItem('scanner', checked);
    }

    return (
        <>
            <Form
                form={form}
                name="paring"
                initialValues={{
                    remember: true,
                    ...initialValues,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="on"
            >
                <Wrapper>
                    <Container>

                        <Location>

                            {/*Update*/}
                            <Title>Update</Title>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <InputContainer>
                                    <MyDateRangePicker/>
                                </InputContainer>

                                <InputContainer style={{textAlign: 'center'}}>
                                    <p>Scanner</p>
                                    <ToggleDiv onChange={handleSwitchScanner}>
                                        <ToggleInput checked={scanner}/>
                                        <div data-unchecked="On" data-checked="Off"/>
                                    </ToggleDiv>
                                </InputContainer>

                                {/*    Is Outside Kiosk */}
                                <KioskTypeSwitcher onTypeChange={(type) => console.log("Kiosk Type:", type)}/>
                                <SplashTypeSwitcher onTypeChange={(type) => console.log("Kiosk Type:", type)}/>

                            </div>
                            {/*Tex*/}
                            <Title>Tax</Title>
                            <InputContainer>
                                <p>Surcharge:</p>
                                <Form.Item
                                    name="surcharge"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your Surcharge",
                                        },
                                    ]}
                                >
                                    <input
                                        placeholder="%"
                                        id="surcharge"
                                        ref={surchargeRef}
                                        data-kioskboard-type="keyboard"
                                        name="surcharge"
                                        type="number"
                                        step="any"
                                    />
                                </Form.Item>
                            </InputContainer>


                            <Title>Location</Title>
                            <InputContainer>
                                <p>Store Location:</p>
                                <Form.Item
                                    name="storeLocation"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your Store location!",
                                        },
                                    ]}
                                >
                                    <input
                                        id="storeLocation"
                                        ref={storeLocationRef}
                                        data-kioskboard-type="keyboard"
                                        name="storeLocation"
                                        type="text"
                                    />
                                </Form.Item>
                            </InputContainer>

                            {/* Store Code */}
                            <Title>Store Code</Title>
                            <InputContainer>
                                <p>Store Code:</p>
                                <Form.Item
                                    name="storeCode"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your Store Code!",
                                        },
                                    ]}
                                >
                                    <input
                                        id="storeCode"
                                        ref={storeCodeRef}
                                        data-kioskboard-type="keyboard"
                                        name="storeCode"
                                        type="text"
                                    />
                                </Form.Item>
                            </InputContainer>

                            {/* Address */}
                            <Title>Address</Title>
                            <InputContainer>
                                <p>POS Address:</p>
                                <Form.Item
                                    name="address"
                                    rules={[
                                        {
                                            required: false,
                                            message: "Please input your Store Address!",
                                        },
                                    ]}
                                >
                                    <input
                                        id="address"
                                        ref={addressRef}
                                        data-kioskboard-type="keyboard"
                                        name="address"
                                        type="text"
                                    />
                                </Form.Item>
                            </InputContainer>
                            {/* Phone number */}
                            <Title>Phone Number</Title>
                            <InputContainer>
                                <p>Phone Number:</p>
                                <Form.Item
                                    name="phoneNumber"
                                    rules={[
                                        {
                                            required: false,
                                            message: "Please input your Store phone number!",
                                        },
                                    ]}
                                >
                                    <input
                                        id="phoneNumber"
                                        ref={phoneNumberRef}
                                        data-kioskboard-type="keyboard"
                                        name="phoneNumber"
                                        type="number"
                                    />
                                </Form.Item>
                            </InputContainer>
                        </Location>
                        <BtnContainer>
                            <SubmitBtn type="submit">Submit</SubmitBtn>
                        </BtnContainer>
                    </Container>
                </Wrapper>
            </Form>
        </>
    );
};

export default PosData;
